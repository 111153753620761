<template>
  <div class="auth-wrapper auth-v2 h-100 d-flex align-items-center">
    <b-row class="auth-inner m-0 h-100 align-items-center flex justify-content-center">

      <!-- Brand logo-->
      <!-- <b-link class="brand-logo">
        <h2 class="brand-text text-primary ml-1">
          {{ ENV.VUE_APP_NAME }}
        </h2>
      </b-link> -->
      <!-- /Brand logo-->

      <b-col
        lg="4"
        class="p-3 pt-5 card"
      >
        <div class="text-center">
          <vuexy-logo />
        </div>
        <b-card-title
          title-tag="h2"
          class="font-weight-bold mb-1"
        >
          <!-- Welcome to {{ ENV.VUE_APP_NAME }}! 👋 -->
        </b-card-title>
        <b-card-text class="mb-2">
          {{ $t('messages.login-info') }}
        </b-card-text>

        <!-- form -->
        <validation-observer ref="loginValidation">
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >
            <!-- email -->
            <b-form-group
              :label="$t('Email')"
              label-for="login-email"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required|email"
              >
                <b-form-input
                  id="login-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="login-email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- forgot password -->
            <b-form-group class="mb-3">
              <div class="d-flex justify-content-between">
                <label for="login-password">{{ $t('Password') }}</label>
                <b-link :to="{name:'forgot-password'}">
                  <small class="text-workzone">{{ $t('Forgot Password?') }}</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="login-password"
                    v-model="password"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="login-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <!-- <b-form-group class="mb-2">
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                Remember Me
              </b-form-checkbox>
            </b-form-group> -->

            <!-- submit buttons -->
            <b-button
              type="submit"
              variant="primary"
              block
              class="btn-workzone w-75 mx-auto py-1"
              :disabled="isProcessing"
              @click="validationForm"
            >
              <b-spinner
                v-show="isProcessing"
                small
              />  {{ $t('Login') }}
            </b-button>
          </b-form>
        </validation-observer>

        <!-- divider -->
        <!-- <div class="divider my-2">
          <div class="divider-text">
            or
          </div>
        </div> -->

        <!-- social buttons -->
        <!-- <div class="auth-footer-btn d-flex justify-content-center">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            tag="a"
            :href="GEC_OAUTH_2_URL"
            class="btn-outline-workzone"
          >
            <img
              src="https://edu.globalenglishcampus.com/logo.png"
              height="24"
            >
            Login With GEC
          </b-button>
        </div> -->
      </b-col>

    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BCardTitle, BForm, BButton, BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import useApollo from '@/plugins/graphql/useApollo'
import useJwt from '@/auth/jwt/useJwt'
import Ripple from 'vue-ripple-directive'
import { setUserData } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rules import store from '@/store/index'
      required,
      email,
      ENV: process.env,
      isProcessing: false,
      GEC_OAUTH_2_URL: process.env.VUE_APP_GEC_OAUTH,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.isProcessing = true
          useApollo.auth.login({
            username: this.userEmail,
            password: this.password,
          }).then(response => {
            const userData = response.data.login
            const data = userData.user
            setUserData(data)
            useJwt.setToken(userData.access_token)

            setTimeout(() => {
              this.$router.replace({ name: 'home' }).then(() => {
                this.$store.dispatch('project/STORE_SELECTED_PROJECT', null)
                // this.$store.dispatch('authEvents/onUserLogin')
                this.showSuccessMessage({
                  data: {
                    message: `Welcome ${userData.user.name}`,
                  },
                })
              })
            }, 500)
          })
            .catch(error => {
              if (error.response?.data) {
                if (error.response?.data?.errors) this.$refs.loginForm.setErrors(error.response.data.errors)
                if (error.response?.data?.message) {
                  this.showErrorMessage(error.graphQLErrors[0]?.extensions.reason)
                }
              } else {
                this.showErrorMessage(error.graphQLErrors[0]?.extensions.reason)
              }
            }).finally(() => {
              this.isProcessing = false
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
